/* @include media-breakpoint-down(sm) { */
/*   body { */
/*     background: #0ff !important; */
/*   } */
/* } */

/* @include media-breakpoint-down(md) { */
/*   body { */
/*     background: #ff0 !important; */
/*   } */
/* } */

/* @include media-breakpoint-down(lg) { */
/*   body { */
/*     background: #f00 !important; */
/*   } */
/* } */

/* @include media-breakpoint-down(xl) { */
/*   body { */
/*     xxbackground: #f0f !important; */
/*   } */
/* } */

#mobile-social-links {
  margin-top: 2em;
  border-top: $header-bg-color 1px solid;
  border-bottom: $header-bg-color 1px solid;

  h2 {
    margin-top: 10px;
  }
}

@include media-breakpoint-down(md) {
  #header {
    font-size: 90%;
    h1 {
      font-size: 18px;
    }
  }
}

@include media-breakpoint-up(xl) {
  #header {
    font-size: 110%;
    h1 {
      font-size: 25px;
    }
  }
}


@include media-breakpoint-down(lg) {
  #header .navbar .nav-donations,
  #site-navigation .nav-donations {
    .inactive .donation-nav-text {
      display: none;
    }
  }

  body.app-public-home-index .donation-types-list {
    .panel-body {
      min-height: 8em;
    }
  }
}

@include media-breakpoint-down(md) {
  #content-container {
    margin-top: 70px;
  }

  #header {
    border-top: 0;
    border-bottom: 3px $site-color solid;
    box-shadow: none;

    .agency-logo img {
      max-height: 37px;
    }

    nav.navbar {
      padding: 0;
    }

    .nav-donations {
      font-size: 90%;
      .nav-pills li > a {
	padding-right: 10px;
	padding-left: 0;
      }
    }

    #site-navigation {
      padding-top: 0;
      padding-left: 15px;
    }
  }
}

@include media-breakpoint-down(sm) {
  body.app-public-home-index {
    #section-intro {
      .agency-footer-image {
	display: block;
	margin: 0 auto;
      }
    }

    #section-donation-types .donation-types-list .card .card-body {
      min-height: 0;
    }
  }

  #header {
    .agency-logo img {
      height: 37px;
      max-width: unset;
    }

    .navbar .nav-donations {
      .nav-pills li > a {
	padding-left: 0px;
	margin-right: 3px;

	.icon-mimabo::before {
	  margin: 0 17px -1em -5px;
	}

	.donation-nav-text {
	  display: none;
	}
      }
    }
  }
}
