/* DEBUG */
/* .container-lg { */
/*   border: 1px solid #f99; */
/* } */
/* .container-lg .row { */
/*   border: 1px solid #9f9; */
/* } */
/* .container-lg .row > div { */
/*   border: 1px solid #f9f; */
/* } */
/* DEBUG END */

#print-header {
  display: none;
}

#content-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 140px;
}

.agency-logo {
  max-height: 70px;
  max-width: 300px;
  img {
    margin-left: 0px;
    margin-bottom: 0px;
    max-width: 100%;
    max-height: 70px;
  }
}

#footer {
  background-color: $site-bg-color;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 100%;
  line-height: 1.5em;
}

#public-footer {
  margin-top: 30px;
  background-color: $footer-bg-color;
  margin-top: 30px;

  #footer-spot-agency1 {
    padding-top: 2em;
  }
  #footer-spot-agency1,
  #footer-spot-agency2 {
    border-top: 2px $site-bg-color solid;
  }
  #footer-spot-one {
    border-bottom: 2px $site-bg-color solid;
    border-top: 2px $site-bg-color solid;
  }
  #footer-spot-one,
  #footer-spot-two {
    border-left: 2px $site-bg-color solid;
  }
}

.user-navigation {
  button.btn-secondary {
    background-color: $site-color;
    border: none;
  }
}

/* FIXME: this is not only used in footer */
.agency-footer-image {
  @extend .rounded-circle;
  display: inline-block;
  max-width: 200px;
  max-height: 200px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

#main {
  border: $page-border-width $page-border-color solid;
  padding-top:    15px;
  padding-bottom: 15px;

  h3 {
    color: $heading-h3-color;
  }
}

.actions {
  padding-top: 10px;
  border-top: 1px $page-border-color solid;
}

label.required:after {
  content:" *";
}

#header {
  border-top: 10px $site-color solid;
  background: #fff;
  box-shadow: 0px 2px 5px 0px #999;
  font-size: 0.9rem;
  padding: 5px 0 5px 0;
  nav.navbar {
    padding-left: 6px;
    padding-right: 6px;
  }
  #site-navigation {
    font-size: 125%;
    padding-top: 20px;
    padding-left: 0px;
  }
  #site-menu {
    float: right;
    position: relative;
    z-index: 999;
    height: 0;
    overflow: visible;
    color: darken($header-bg-color, 10%);
    a {
      color: darken($header-bg-color, 10%);
    }
  }
}

#content-navigation {
  font-size: 133%;
  margin-bottom: 10px;

  .nav {
    li > a {
      position: relative;
      padding: 0.25rem 0.75rem;
      margin-right: 1em;
      color: #748a8e;

      &:hover {
	background-color: #fff;
	color:#444;
      }

      &.active {
	color: #fff;
	background-color: #bfbdc2;

	&:before {
	  content: "";
	  position: absolute;
	  right: 50%;
	  top: 100%;
	  width: 0;
	  height: 0;
	  margin-right: -0.35em;
	  border-left: 0.35em solid transparent;
	  border-top: 0.35em solid $navigation-bg-color;
	  border-right: 0.35em solid transparent;
	}
      }
    }
  }
}

.nav-donations {
  .nav-pills {
    li.nav-time > a {
      border-bottom-color: $time-color;
    }
    li.nav-money > a {
      border-bottom-color: $money-color;
    }
    li.nav-goods > a {
      border-bottom-color: $goods-color;
    }
    li.active.nav-time > a {
      background-color: $time-color;
      &:before { border-top-color: $time-color }
    }
    li.active.nav-money > a {
      background-color: $money-color;
      &:before { border-top-color: $money-color }
    }
    li.active.nav-goods > a {
      background-color: $goods-color;
      &:before { border-top-color: $goods-color }
    }
    li.active > a {
      border-bottom: 0px;
      padding-bottom: 7px;
      color: $navigation-active-color;
      background-color: $navigation-bg-color;
      &:hover {
	color: $navigation-active-color;
      }
    }
    li > a {
      margin-right: 1em;
      color: $navigation-color;
      border-bottom: 3px transparent solid;
      padding: 2px 22px 4px 10px;
      &:hover {
	background-color: $navigation-hover-bg-color;
	color: $navigation-hover-color;
	text-decoration: none;
      }
      .icon-mimabo {
	text-align: right;
      }
    }
    li:last-child > a {
      margin-right: 0;
    }
  }
}

/* .table { */

/*   border-left: 1px $table-head-bg-color solid; */

/*   tbody { */
/*     border: 1px #ccc solid; */
/*   } */

/*   thead { */
/*     background: $table-head-bg-color; */
/*     color: #fff; */
/*   } */
/*   > thead > tr > th { */
/*     border-bottom: 0px; */
/*     font-weight: normal; */
/*     padding: 5px 5px 5px 10px; */
/*   } */
/*   > tbody > tr > td { */
/*     border-top: 0px; */
/*     padding: 15px 5px 15px 10px; */
/*   } */
/* } */

.dropdown-menu {
  border: 1px solid #ccc;
}

.navigation-container {
  border-bottom: $page-border-width $page-border-color solid;
  padding-bottom: 15px;
}

.no-height {
  min-height: 0px;
}

.no-padding {
  padding: 0px;
}

.alert-container {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 10%;
  width: 80%;
  height: 0;
  .alert {
    box-shadow: 2px 2px 5px #666;
  }
}


.heading {
  margin-bottom: 0.5em;
  display: inline-block;
}

body.time-projects #main h3,
body.time-projects #main .heading {
  color: $time-font-color !important;
}
body.money-projects #main h3,
body.money-projects #main .heading {
  color: $money-font-color !important;
}
body.goods-projects #main h3,
body.goods-projects #main .heading {
  color: $goods-font-color !important;
}

.project-filter-button {
  margin-right: -12px;

  a, a:hover, a:focus {
    color: #fff;
    font-weight: bold;
    height: 2.625em;
    padding-top: 8px;
    text-decoration: none;
    .fa {
      margin-left: 5px;
    }
  }
}

.time-projects .project-filter-button a {
  background: $time-color;
}
.money-projects .project-filter-button a {
  background: $money-color;
}
.goods-projects .project-filter-button a {
  background: $goods-color;
}

.project-type-buttons {
  margin-right: -19px;

  > div > ul {
    display: block;
    clear: both;
    margin-right: -5px;
    margin-bottom: 0;

    > li {
      display: inline-block;
      float: right;
      margin-left: 10px;
    }
  }
  a {
    font-size: 150%;
    border: 2px #bbb solid;
    border-bottom: 0;
    padding: 0px 15px;
    color: #999;
    height: 1.75em;
    &:hover {
      border: 2px #bbb solid;
      border-bottom: 0;
    }
    > span {
      margin-left: -10px;
    }
  }
}
.time-projects .project-type-buttons a.type-time {
  color: #fff;
  background: $time-color;
  border-color: $time-color;
}
.goods-projects .project-type-buttons a.type-goods {
  color: #fff;
  background: $goods-color;
  border-color: $goods-color;
}
.money-projects .project-type-buttons a.type-money {
  color: #fff;
  background: $money-color;
  border-color: $money-color;
}

.navbar-toggler {
  float: right;
  border: 0;
}

.max-width {
  max-width: 1225px;
}

/* SPAM honeypot */
#sint {
  display: none;
}

/* remove ukraine flag */
.leaflet-map .leaflet-attribution-flag {
  display: none !important;
}
