body.app-public-home-index {
  #section-donation-types {
    .type-time {
      background-color: $time-color;
      border-color: $time-color;
    }
    .type-money {
      background-color: $money-color;
      border-color: $money-color;
    }
    .type-goods {
      background-color: $goods-color;
      border-color: $goods-color;
    }

    .project-count {
      a {
	color: #666;
      }
      font-size: 120%;
      margin-top: 0.5em;
    }

    .donation-types-list {
      a:hover {
	text-decoration: none !important;
      }
      .card {
	/* position: relative; */
	/* box-shadow: none; */
	border-width: 3px;
	transition: all .1s ease-in-out;
	cursor: pointer;
	margin-top: 4em;
	margin-bottom: 2em;
	&:hover {
	  transform: scale(1.05);
	}
	/* arrow */
	&:before {
	  content: "";
	  position: absolute;
	  right: 50%;
	  top: 100%;
	  width: 0;
	  height: 0;
	  margin-right: -2em;
	  border-left: 2em solid transparent;
	  border-top: 2em solid $navigation-bg-color;
	  border-right: 2em solid transparent;
	}

	&.type-time:before {
	  border-top-color: $time-color;
	}
	&.type-money:before {
	  border-top-color: $money-color;
	}
	&.type-goods:before {
	  border-top-color: $goods-color;
	}

	.card-header {
	  padding: 5px 0px;
	  border-bottom: 0;
	  span.donation-nav-title {
	    color: $navigation-active-color;
	    font-size: 133%;
	    padding: 2px 12px 7px 10px;
	    display: block;
	  }
	  .icon-mimabo:before {
	    font-size: 140%;
	    margin: 0 .75em -1em 0;
	  }
	}

	.card-body {
	  background-color: #f9f9f9;
	  color: var(--bs-body-color);
	  min-height: 6em;
	}
      }
    }
  }

  #section-organisations {
    .card-body {
      height: 6em;
      overflow: hidden;
    }
  }

  p.button {
    margin: 1em 0;
  }
  p.button a {
    font-size: 110%;
    background-color: lighten(#eb8900, 10%);
    border-color: #eb8900;
  }
  p.button a:focus {
    background-color: #eb8900;
    border-color: darken(#eb8900, 10%);
  }
  p.button a:hover {
    background-color: #eb8900;
    border-color: darken(#eb8900, 10%);
  }
  .target-groups {
    margin-top: 4em;
    padding-bottom: 2em;
    background: #f3f3f3;
    h2 {
      margin-top: 1em;
    }
    h3 {
      margin-top: 2em;
      font-size: 20px;
    }
  }
  h2 {
    font-size: 30px;
    margin: 2em 0em 0em 0em;
    color: $header-bg-color;
  }
  #main {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  /* .jumbotron { */
  /*   background-color: #F3F3F3; */
  /*   margin-left: -12px; */
  /*   margin-right: -12px; */
  /* } */
  /* .jumbotron p { */
  /*   font-size: 17px; */
  /*   color: #444; */
  /* } */
  /* .jumbotron h1 { */
  /*   font-size: 30px; */
  /*   margin-bottom: 1em; */
  /* } */
  #section-intro {
    margin-left: -12px;
    margin-right: -12px;

    .lead {
      font-size: 1rem;
      b, strong {
	font-weight: bold;
      }
    }

    h1 {
      font-size: 2em;
    }
  }

}
