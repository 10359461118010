.project.teaser {
  border-top: $page-border-width $project-border-color solid;
  padding-top: 15px;
  padding-bottom: 15px;
}
body.app-public-projects-index #main {
  padding-top: 0;
}
.project.teaser:first-child {
  border-top: 0;
}

.project-icon {
  img {
    @extend .rounded-circle;
    @extend .img-fluid;
    max-height: 200px;
  }
}

#project-filter {
  border-bottom: $page-border-width $project-border-color solid;
}

body.time-projects #project-filter,
body.time-projects #main,
body.time-projects .project.teaser {
    border-color: $time-color;
}

body.money-projects #project-filter,
body.money-projects #main,
body.money-projects .project.teaser {
    border-color: $money-color;
}

body.goods-projects #project-filter,
body.goods-projects #main,
body.goods-projects .project.teaser {
    border-color: $goods-color;
}

body.time-projects .project-donation-money,
body.time-projects .project-donation-goods {
  display: none;
}

body.money-projects .project-donation-time,
body.money-projects .project-donation-goods {
  display: none;
}

body.goods-projects .project-donation-time,
body.goods-projects .project-donation-money {
  display: none;
}
.project-count {
  font-weight: bold;
  padding-top: 9px;
  display: block;
}
.project.teaser a {
    font-weight: bold;
}
.project.teaser .project-col-two a:after {
    content: " >"
}

.time-projects .project.teaser a {
    color: $time-font-color;
}
.money-projects .project.teaser a {
    color: $money-font-color;
}
.goods-projects .project.teaser a {
    color: $goods-font-color;
}
body.time-projects .project-pager-btn a .fa {
    color: $time-color;
}
body.money-projects .project-pager-btn a .fa {
    color: $money-color;
}
body.goods-projects .project-pager-btn a .fa {
    color: $goods-color;
}
body.time-projects #participate-btn a {
  background-color: $time-color;
}
body.money-projects #participate-btn a {
  background-color: $money-color;
}
body.goods-projects #participate-btn a {
  background-color: $goods-color;
}

#project-location .leaflet-map {
  margin-top: 1em;
  height: 200px;
}

/* hide the button in backend */
body.app-organisation #participate-btn,
body.app-agency #participate-btn {
  display: none;
}

#participate-btn a {

  &:hover { transform: scale(1.15) }
  transition: all .1s ease-in-out;

  margin: 0em 0em 1em 1.5em;
  padding-top: 0.5em;
  border-radius: 50px;
  height: 85px;
  width: 85px;
  display: block;
  color: #fff;
  font-weight: bold;
  outline: 0;

  .fa {
    font-size: 2.0em;
    display: block;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

#participate-form {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;

  &.is-open {
    grid-template-rows: 1fr;
  }

  .inner {
    overflow: hidden;

    form {
      background: #F3F3F3;
      border: 3px $project-border-color solid;
      margin-top: 1em;
      margin-bottom: 1em;
      padding: 1em;
    }
  }
}

/* project pager */

.project-pager-btn a {

  margin: 14px;
  padding: 0px;
  display: block;

  .fa {
      color: #fff;
      font-weight: bold;
      font-size: 4.0em;
      display: block;
  }
  .fa-left {
      margin-right: 15px;
      float: left;
  }
  .fa-right {
      margin-left: 15px;
      float: right;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}
.pager-label {
    vertical-align: top;
    color: #3F4142;
    display: block;
}
body.app-public-organisations-index .project-links {
    a {
	display: block;
	&.time-projects { color: $time-color; }
	&.money-projects { color: $money-color; }
	&.goods-projects { color: $goods-color; }
    }
}
