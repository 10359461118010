$site-color: #00C1EA;
$site-bg-color: #fff;
$header-bg-color: $site-color;
$footer-bg-color: #F3F3F3;

$project-border-color: #D5D3D7;
$project-time-color: #77C91C;
$project-money-color: #B21C52;
$project-goods-color: #FFCB0D;

$page-border-color: #D5D3D7;
$page-border-width: 3px;

$heading-h3-color: $site-color;

$navigation-color: #748a8e;
$navigation-active-color: #fff;
$navigation-bg-color: #bfbdc2;
$navigation-hover-bg-color: $navigation-active-color;
$navigation-hover-color: #444;

$table-head-bg-color: #5e5e5e;

$time-color: #77C91C;
$money-color: #B21C52;
$goods-color: #FFCB0D;

$time-font-color: darken($time-color, 10%);
$money-font-color: darken($money-color, 0%);
$goods-font-color: darken($goods-color, 12%);

$tag-bg-color: #e6e5e5;
$tag-bg-active: #5c6266;
$tag-color: #444;
$tag-color-active: #fff;

/* bootstrap variables */
$font-size-base: 0.9rem;

$h1-font-size: $font-size-base * 1.6;
$h2-font-size: $font-size-base * 1.4;
$h3-font-size: $font-size-base * 1.2;
$h4-font-size: $font-size-base * 1.0;

$tooltip-bg: #ddd;
$tooltip-font-size: $font-size-base * 1.0;
$tooltip-color: #111;
