.project-filter-button {
  .btn {
    .is-not-collapsed { display: inline }
    .is-collapsed { display: none }

    &.collapsed {
      .is-not-collapsed { display: none }
      .is-collapsed { display: inline }
    }
  }
}
