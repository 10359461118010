:root,
[data-bs-theme=light] {
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-body-color: #3f4142;
  --bs-body-color-rgb: 63, 65, 66;
  --bs-body-font-size: 0.9rem;
  --bs-font-sans-serif: Arial, sans-serif;
  --bs-tertiary-bg: #f3f3f3;
  --bs-tertiary-bg-rgb: 243, 243, 243;

  --bs-link-decoration: none;
  --bs-link-color: #337ab7;
  --bs-link-color-rgb: 51, 122, 183;
  --bs-link-hover-color: #23527c;
  --bs-link-hover-color-rgb: 35, 82, 124;

  --bs-border-radius: 0;
  --bs-border-radius-sm: 0;
  --bs-border-radius-lg: 0;
  --bs-border-radius-xl: 0;
  --bs-border-radius-xxl: 0;

  /* not usable in bootstrap 5.3 */
  /* --bs-tooltip-bg: #f00; */
  /* --bs-tooltip-font-size: 20px; */
}

.navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 193, 234, 1%29' stroke-linecap='butt' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-color: #fff;
}

a {
  text-decoration: var(--bs-link-decoration);

  &:hover {
    text-decoration: underline;
  }

  &.btn:hover {
    text-decoration: none;
  }
}

/* customize bootstrap/_forms.scss */
.form-control {
  border: 2px solid $input-border-color;
}
label {
  font-weight: normal;
}
.form-horizontal .control-label {
  padding-top: 5px;
}
