.tag-label.is-clickable label {
  cursor: pointer;
}

.tag-label {
  position: relative;
  display: inline-block;
  margin-left: 0.75em;
  margin-bottom: 0.5em;

  input {
    display: none;
  }

  label {
    color: $tag-color;
    font-size: 1em;
    font-weight: normal;
    line-height: 2em;
    padding: 0 1em;
    background-color: $tag-bg-color;
    border-radius: 0;

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-right: 0.75em solid $tag-bg-color;
      border-bottom: 1em solid transparent;
    }
  }

  &.is-active > label,
  input:checked + label {
    background-color: $tag-bg-active;
    color: $tag-color-active;
    &:before {
      border-right-color: $tag-bg-active;
    }
  }
}
