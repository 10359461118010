.shariff {
  .orientation-horizontal li {
    min-width: 0px;
    height: 40px;
    width: 60px;
    flex: none;
  }
  li.shariff-button a {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    .fab, .fas {
      margin-top: 5px;
    }
  }
}
