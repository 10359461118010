@font-face {
  font-family: "mimabo-icon-font";
  src: url("../fonts/icons.ttf");
  font-weight: normal;
  font-style: normal;
}

.icon-mimabo-time:before {
  content: "z";
}
.icon-mimabo-goods:before {
  content: "s";
}
.icon-mimabo-money:before {
  content: "g";
}

.icon-mimabo:before {
  font-family: 'mimabo-icon-font';
  speak: none;
  -webkit-font-smoothing: antialiased;
  min-width: 25px;
  display: inline-block;
  font-size: 120%;
}

.nav-pills .icon-mimabo:before {
  font-size: 140%;
  margin: 0 .75em -1em 0;
}
