@media print {

    #new-header,
    .navbar,
    #site-menu,
    #site-navigation,
    #sidebar,
    .project-type-buttons,
    #participate-btn,
    .project-icon,
    .project-social-links,
    #public-footer,
    #footer,
    #project-pager,
    .leaflet-control-container,
    .organisation-filter {
	display: none !important;
    }

    a[href]::after {
	display: none;
    }

    .tag-label::before {
	border: none;
    }
    .label {
	border: none;
    }

    #main {
	margin-top: -50px;
    }

    #project-location {
	page-break-inside: avoid;
    }

    .col-print-12 {
	width: 100%;
    }
    .visible-print {
	display: inherit !important;
    }
    .hidden-print {
	display: none !important;
    }

    #print-header {
	display: block;
	margin-bottom: -120px;
    }
}