.organisation-logo {
  margin-bottom: 1em;
  img {
    max-width: 100%;
    max-height: 50px;
  }
}
.organisation-name {
  min-height: 50px;
  font-weight: bold;
}
.organisation-filter {
  h2 {
    margin-top: -20px;
  }
}
.reset-organisation-filter {
    font-size: 120%;
}